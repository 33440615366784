<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { z } from 'zod';
import GoogleAuth from './GoogleAuth.vue';
import SsoAuth from './SsoAuth.vue';

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
});

const userStore = useUserStore();
const { login, register } = userStore;
const { routeName } = useRouteName();

const isHidingPassword = ref(true);
const form = ref(null);
const isLoading = ref(false);

const isRegister = computed(() => routeName.value === 'register');

type Schema = z.output<typeof schema>;

const state = reactive({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
});

const authSchema = computed(() => {
  const schema = {
    email: z.string().email('Invalid email'),
    password: isRegister.value
      ? z.string().min(12, 'Must be at least 12 characters')
      : z.string(),
  };

  if (isRegister.value) {
    schema.firstName = z
      .string()
      .min(1, { message: 'First name is required' })
      .refine((value) => /^[a-zA-Z]+$/.test(value), {
        message: 'First name should only contain letters',
      });

    schema.lastName = z
      .string()
      .min(1, { message: 'Last name is required' })
      .refine((value) => /^[a-zA-Z]+$/.test(value), {
        message: 'Last name should only contain letters',
      });
  }

  return schema;
});

const schema = computed(() => z.object(authSchema.value));

async function onSubmit(event: FormSubmitEvent<Schema>) {
  isLoading.value = true;

  const {
    data: { firstName, lastName, email, password },
  } = event as {
    data: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    };
  };

  const fullName = firstName.concat(' ', lastName);

  const response = isRegister.value
    ? await register({ fullName, email, password })
    : await login({ email, password });

  if (response?.error) {
    form.value?.errors?.push({
      path: 'password',
      message: isRegister.value
        ? 'Check email for activation link.'
        : 'Incorrect email or password',
    });
    isLoading.value = false;
    return;
  }

  if (isRegister.value) {
    await login({ email, password });
  }

  await navigateTo('/');
  isLoading.value = false;
}
</script>
<template>
  <NuxtLayout name="auth">
    <p class="title">
      {{ isRegister ? 'Create an account' : 'Log in to your account' }}
    </p>
    <template v-if="isRegister">
      <p class="body dimmed text-center">
        Respell is joining Salesforce. Signups are disabled.
        <br />
        For help, contact
        <u><a href="mailto:matt@respell.ai">matt@respell.ai</a></u>
      </p>
      <span class="flex">
        <p class="body dimmed">Already have an account?</p>
        <UButton
          variant="link"
          :to="{ name: 'login' }"
          size="xl"
          label="Log in"
        />
      </span>
    </template>
    <template v-else>
      <GoogleAuth :disabled="isRegister" />
      <SsoAuth :disabled="isRegister" />
      <UDivider label="or" :ui="{ label: 'text-gray-400' }" />
      <UForm
        ref="form"
        :schema="schema"
        :state="state"
        class="contents"
        @submit="onSubmit"
      >
        <UFormGroup
          name="email"
          eager-validation
          required
          label="Email"
          size="xl"
          class="w-full"
        >
          <UInput v-model="state.email" icon="i-ph-envelope" />
        </UFormGroup>
        <UFormGroup
          size="xl"
          eager-validation
          name="password"
          required
          label="Password"
          class="w-full"
        >
          <UInput
            v-model="state.password"
            icon="i-ph-lock"
            :type="isHidingPassword ? 'password' : undefined"
            :ui="{ icon: { trailing: { pointer: '' } } }"
          >
            <template #trailing>
              <UButton
                :icon="isHidingPassword ? 'i-ph-eye' : 'i-ph-eye-slash'"
                variant="link"
                color="gray"
                @click="isHidingPassword = !isHidingPassword"
              />
            </template>
          </UInput>
        </UFormGroup>

        <UButton
          block
          size="xl"
          :loading="isLoading"
          label="Log in"
          type="submit"
        />
      </UForm>
      <span class="flex">
        <p class="body dimmed">Don't have an account?</p>
        <UButton
          variant="link"
          :to="{ name: 'register' }"
          size="xl"
          label="Create an account"
        />
      </span>
    </template>
  </NuxtLayout>
</template>
